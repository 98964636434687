import styles from './Effect.module.css'

export default function Effect({ handleEffectChange, imgSrc, effectObject, currentEffect, children }) {


  return (
    <button
      onClick={() => handleEffectChange(effectObject)}
      className={currentEffect.dataEffect === effectObject.dataEffect ? styles.currentEffect : styles.effect}>
      {/* <img src={imgSrc} alt="" className={styles.effectImage} /> */}
      {children}
    </button>
  )
}