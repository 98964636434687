import styles from './History.module.css'
import saveImage from '../tools/saveImg'

export default function History({ edits, setCurrentImage }) {

  function handleHistoryChange(edit) {
    setCurrentImage(edit)
  }

  function renderEdits() {

    return edits.length > 0 && edits.map((edit, index) => {

      return (
        <button className={styles.historyItem}
          onClick={() => saveImage(edit.outputImage)}
          key={index}>

          <p>#{edits.length - index}, {edit.effect}</p>
          <img src={edit.outputImage}
            alt='output'
            className={styles.image} />

          {/* <button
            className={styles.saveImage}
            onClick={() => handleHistoryChange(edit)}
            key={index}>
            Select
          </button> */}
          <p
            className={styles.saveImage}
          >
            Tap to Save
          </p>
        </button>
      )
    })
  }

  return (
    <div>
      Edits
      <div className={styles.historyMap}>
        {renderEdits()}
        <button className={styles.historyItem}>

          <p>Original</p>
          <img src={edits[edits.length - 1]?.inputImage} alt='input' style={{ maxWidth: '100%' }} />
          {/* <button
            className={styles.saveImage}
            onClick={() => handleHistoryChange(edits[edits.length - 1]?.inputImage)}>
            Select
          </button> */}
          <p
            className={styles.saveImage}
            onClick={() => saveImage(edits[edits.length - 1]?.inputImage)}>
            Tap to Save
          </p>
        </button>
      </div>
    </div>
  )
}