
export default function saveImage(imgUrl) {
  const a = document.createElement("a");
  a.href = imgUrl;
  a.target = "_blank";
  a.download = true;
  a.rel = "noreferrer";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}