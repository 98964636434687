import React from "react";

import styles from './Footer.module.css';
import { Link } from "react-router-dom"
import devLogo from "../assets/devlogo.png"
import SocialIcons from './SocialIcons.js';

function Footer() {

  return (
    <footer className={styles.footerWrapAbstract}>
      <div className={styles.footerWrap}>
        {/* <menu className={styles.footerLinks}> */}
        {/* <label>Practical</label> */}
        {/* <li>
            <Link to="/blogs/65620398533246e4184cd52b" className={styles.LinkComp} >
            </Link>
          </li>
          <li>
            <Link to="/support"
              className={styles.LinkComp}>

            </Link>
          </li>
          <li>
            <Link className={styles.LinkComp} to="/blog/1">

            </Link>
          </li>

          <li>
            <Link to="/faqs"
              className={styles.LinkComp}>

            </Link>
          </li>
        </menu> */}

        <menu className={styles.footerLinks}>
          <label>Other Services</label>
          <li><a href='https://aicatz.com/' className={styles.LinkComp}>Free Cat Name Generator - Ai Catz &#8599;</a></li>

          <li><Link className={styles.LinkComp} to="https://aipetphotos.com/product/cat-portrait-ai-4k">AiPetPhotos.com - Cat Portraits &#8599;</Link></li>


          <li><a href='https://ai-dog-generator.com/' className={styles.LinkComp}>Free Dog Name Generator &#8599;</a></li>
          <li><Link className={styles.LinkComp} to="https://aipetphotos.com/product/dog-portrait-ai-4k">AiPetPhotos.com - Dog Portraits &#8599;</Link></li>

          <li><a href='https://furry-art.com/' className={styles.LinkComp}>Free Pet Name Generator - Furry Art &#8599;</a></li>



          {/* <li><Link className={styles.LinkComp} to="/product/dog-portraits-automatic-ai">AI Dog Portraits</Link></li> */}

        </menu>

        {/* <div className={styles.footerLinks}>
          <label>Social</label>
          <Suspense fallback={<div>Loading...</div>}>
            <SocialIcons />
          </Suspense>
        </div>
 */}


        {/* <menu className={styles.footerLinks}>
          <label>Legal</label>
          <Suspense fallback={<div>Loading...</div>}>
            <li><Link className={styles.LinkComp} to="/terms">Terms</Link></li>
            <li><Link className={styles.LinkComp} to="/privacy">Privacy</Link></li>
            <li><Link className={styles.LinkComp} to="/affiliate">Affiliates</Link></li>
          </Suspense>
        </menu> */}

        <div className={styles.footerLinks}>
          {/* <li><Link className={styles.LinkComp} to="/about">About</Link></li> */}

          <li>
            <a className={styles.LinkComp} href="https://aifotoedit.com/sitemap.xml">
              Sitemap
            </a>
          </li>


          <p className={styles.rights}>All rights reserved. Sensei AI Ltd. 2023</p>

          <img className={styles.devLogo} src={devLogo} alt="founder logo" />
        </div>
      </div>

    </footer>

  );
}

export default Footer;
