import React, { useState, createContext } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { baseUrl } from '../config'
import styles from './Login.module.css'
import { getLoggedInUserId } from "./auth.js";
import { Link } from "react-router-dom"

export default function Login() {


  const [buttonLabel, setButtonLabel] = useState('SIGN IN / REGISTER')
  const [error, setError] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();

  const [loginDataForm, setLoginDataForm] = useState({
    email: "",
    password: ""
  })

  function handleChange(e) {
    const cleanedValue = e.target.value.trim();
    setLoginDataForm((prevState) => {
      return {
        ...prevState,
        [e.target.name]: cleanedValue,
      }
    })
  }

  React.useEffect(() => {
    const loggedIN = getLoggedInUserId();
    loggedIN && setButtonLabel("Already logged in.")
  }, [])

  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  function isValidPassword(password) {
    if (password.length <= 50 && password.length > 8) {
      return true;
    } else {
      return false;
    }
  }

  function validateForm() {
    if (!loginDataForm.email || !loginDataForm.password) {
      console.log("no email or password");
      setError("Please enter an email and a password.")
      return false;
    }

    if (loginDataForm.email && !isValidEmail(loginDataForm.email)) {
      console.log("invalid email");
      setError("Email must be shorter than 50 characters and in the correct format.");
      return false;
    }

    if (loginDataForm.password && !isValidPassword(loginDataForm.password)) {
      console.log("invalid password");
      setError("Password must be between 10 and 50 characters long.");
      return false;
    }

    if (loginDataForm.email && loginDataForm.email.includes('@qq.com')) {
      setError("Emails from qq.com domain are not accepted.");
      return false;
    }
    if (loginDataForm.email && loginDataForm.email.includes('@88.com')) {
      setError("Emails from 88.com domain are not accepted.");
      return false;
    }
    return true;
  }





  //!MAIN FUNCTION
  async function handleButton(e) {
    setButtonDisabled(true)
    e.preventDefault()

    if (!validateForm()) { //validate form
      setButtonDisabled(false)
      setButtonLabel('Login | Register')
      return
    }

    setError(undefined)


    try {
      setButtonLabel('Attempting Authentication ...')
      let token = localStorage.getItem("token");

      if (!token && loginDataForm.email && loginDataForm.password) {
        // Try to log in
        try {
          // console.log(111, baseUrl);
          // console.log(33, process.env.REACT_APP_ENV);

          let response1 = await axios.post(`${baseUrl}/logindata`, loginDataForm);
          // console.log(response1.data.data);

          if (response1.status === 200 && response1.data.data.token) {
            localStorage.setItem("token", response1.data.data.token);
            // localStorage.setItem("tokens", response1.data.data.tokens);

            localStorage.setItem("tokenTimestamp", Date.now().toString());

            location.pathname !== '/' && navigate('/projects')
            location.pathname === '/' && navigate('/')

          }

        } catch (err) {
          console.log(err);

          if (err.response) {
            if (err.response.status === 401) {
              setError("Wrong password. Please try again.")
              setButtonDisabled(false)
              setButtonLabel('SIGN IN')
              return;

            } else if (err.response.status === 404) {
              // If login fails, try to register and log in

              //! START TO REGISTER
              try {
                console.log("attempting reg");
                setButtonLabel('Attempting Registration ...');
                const regResponse = await axios.post(`${baseUrl}/register`, loginDataForm);

                if (regResponse.status === 400) {
                  setError("Registration failed due to too long or too short password. Please try again.");
                  setButtonLabel('REGISTER')
                  setButtonDisabled(false)
                  return
                }

                //! LOGIN AFTER REGISTER
                console.log("attempting login");
                let response2 = await axios.post(`${baseUrl}/logindata`, loginDataForm);

                if (response2.status === 200) {
                  // console.log("register loggedin token", response2.data.token);
                  localStorage.setItem("token", response2.data.token);
                  localStorage.setItem("tokenTimestamp", Date.now().toString());
                  // localStorage.setItem("tokens", response2.data.tokens);

                  location.pathname !== '/' && navigate('/projects')
                  location.pathname === '/' && navigate('/')
                }
              } catch (regError) {
                console.log(regError);
                if (regError.response && regError.response.status === 400) {
                  setError("Registration failed. Invalid data.");
                } else if (regError.response && regError.response.status === 500) {
                  setError("Registration failed. Server error.");
                } else {
                  setError("Registration failed. Unknown error.");
                }
                setButtonLabel('SIGN IN / REGISTER')
                setButtonDisabled(false)

              }
            }
          } else {
            setError("Connection error. Please try again.")
            setButtonLabel('SIGN IN / REGISTER')
            setButtonDisabled(false)

          }
        }
      }
    } catch (e) {
      console.log(e);
      setError("An unexpected error occurred. Please try again.")
      setButtonLabel('SIGN IN / REGISTER')
      setButtonDisabled(false)

    }
  }



  return (
    <span className={styles.wrap}>
      {getLoggedInUserId() && <p>You are logged in.</p>}
      {getLoggedInUserId() && <Link to="/myprofile">Profile Page</Link>}

      <form className={styles.form}>

        <span>

          <label
            className={styles.label}>
            Email:
          </label>

          <input
            disabled={getLoggedInUserId()}
            className={styles.input}
            type="email"
            name={'email'}
            value={loginDataForm.email}
            onChange={handleChange}
            placeholder="Enter your email"
            autoComplete="on"
            maxLength="100"

          />
        </span>

        <span>

          <label
            className={styles.label}
          >Password:
          </label>

          <input
            disabled={getLoggedInUserId()}
            className={styles.input}
            type="password"
            name={'password'}
            value={loginDataForm.password}
            onChange={handleChange}
            placeholder="Enter password e.g. catCloudBoardCoffee"
            autoComplete="on"
            maxLength="100"
          />
        </span>

        {error && <p>{error}</p>}
        <button
          disabled={buttonDisabled}
          onClick={handleButton}>
          {buttonLabel}
        </button>

        <small>New users are registered. Existing are signed in.</small>
      </form>


      {/* <button
        onClick={handleRegister}>
        register
      </button> */}

    </span >
  )




}





