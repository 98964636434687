import axios from 'axios'
import { baseUrl } from '../config';

export default async function submitEdit(form) {
  const tokens = localStorage.getItem('tokens')

  if (tokens < 1) {
    alert("You are out of tokens, please purchase some and resubmit. Thank you.")
    return false
  }

  try {
    const token = localStorage.getItem('token')
    const response = await axios.post(`${baseUrl}/edit`, form,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 201) {
      let updatedTokens = tokens - 1
      localStorage.setItem("tokens", updatedTokens);
      alert("Edit Submitted Successfully. Results will be ready soon.")
    }

    return response

  } catch (error) {
    console.log('ERROR', error);
    throw new Error(error)
  }

}