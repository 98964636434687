import React, { useEffect, useRef, useState } from 'react'
import styles from './Editor.module.css'
import axios from 'axios'
import { baseUrl } from '../config.js'
import submitEdit from '../tools/submitEdit.js'
import ForwardedCanvasComponent from '../effects/objectRemoval.js';
import GenBackground from '../effects/genBackground.js';
import Effects from '../ui/Effects.js';
import EventListenerComponent from '../services/Events.js';
import { useParams, useLocation } from "react-router-dom"
// import AddOns from '../ui/AddOns.js'
import History from '../ui/History.js'
import ButtonA from '../ui/ButtonA.js'


export default function Editor() {
  const [events, setEvents] = useState([])

  const [edits, setEdits] = useState([])
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [currentImage, setCurrentImage] = useState({
    inputImage: '',
    outputImage: ''
  });
  const [currentEffect, setCurrentEffect] = useState('');

  const { projectId } = useParams()

  const location = useLocation();
  const projectName = location.state?.name || edits[0]?.project.name;

  useEffect(() => {
    async function fetchEdits() {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get(`${baseUrl}/edits/${projectId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        if (response.status === 200) {
          setEdits(currentEdits => {
            const currentEditIds = new Set(currentEdits.map(edit => edit.id));

            // Filter out new edits that are already in the current edits
            const newEdits = response.data.filter(edit => !currentEditIds.has(edit.id)).reverse();

            // Combine the current edits with the filtered new edits
            return [...newEdits, ...currentEdits];
          }
          )
        }

      } catch (e) {
        console.log(e);
      }
    }

    fetchEdits();
  }, [projectId, events])

  const handleUndo = () => {
    if (undoStack.length > 0) {
      const newUndoStack = [...undoStack];
      const prevImage = newUndoStack.pop();
      setRedoStack([...redoStack, currentImage]);
      setCurrentImage(prevImage);
      setUndoStack(newUndoStack);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const newRedoStack = [...redoStack];
      const nextImage = newRedoStack.pop();
      setUndoStack([...undoStack, currentImage]);
      setCurrentImage(nextImage);
      setRedoStack(newRedoStack);
    }
  };

  const handleChange = (e) => {
    if (currentImage) {
      setUndoStack([...undoStack, currentImage]);
    }
    setRedoStack([]); // Clear redo stack when a new change is made
    setCurrentImage(prev => {
      return {
        ...prev,
        inputImage: e.target.value
      }
    })
  };


  function handleEffectChange(e) {
    const effect = e.currentTarget.dataset.effect;
    setCurrentEffect(effect);
  }

  function handleAddon() {

  }


  function handleConfirm(maskImage) {

    try {
      let form = {
        inputImage: currentImage.outputImage,
        effect: currentEffect,
        // maskImage: maskImage ? maskImage : '',
        project: projectId,
      }
      console.log(form);
      const response = submitEdit(form, 10);

    } catch (e) {
      console.log('error', e);
    }
  }

  const objectRemoveRef = useRef(null);

  function handleSubmitMask() {
    if (objectRemoveRef.current) objectRemoveRef.current.handleSubmitMask();

  }

  function handleCombineImages() {
    if (objectRemoveRef.current) {
      let constMask = objectRemoveRef.current.handleCombineImages();
      setMask(constMask)
    }
  }

  function handleBlack() {
    if (objectRemoveRef.current) {
      const constMask = objectRemoveRef.current.blackBackground();
    }
  }



  const [mask, setMask] = useState('')

  function createBlackAndWhiteMask() {
    if (objectRemoveRef.current) {
      let constMask = objectRemoveRef.current.createBlackAndWhiteMask();
      setMask(constMask)
    }
  }

  function clear() {
    if (objectRemoveRef.current) objectRemoveRef.current.clear();
  }


  return (
    <div className={styles.wrap}>
      <h1>Editor</h1>
      <div className={styles.leftSection}>
        {/* <div className={styles.topUi}>
          <button className={styles.button} onClick={handleUndo}>Undo</button>
          <button className={styles.button} onClick={handleRedo}>Redo</button>
        </div> */}

        <span>
          {/*! add upload button */}

          <label>
            Enter Image Link (URL)
            <input type="text" onChange={handleChange}
              className={styles.inputImageUrl} />
          </label>
        </span>
        {/* <AddOns handleAddon={handleAddon} /> */}
      </div>


      <div className={styles.centerSection}>

        {/* <img src={currentImage.inputImage}
          className={styles.currentImage}
          alt='input'
        /> */}
        <img src={currentImage.outputImage || currentImage.inputImage}
          className={styles.currentImage}
          alt='preview' />

        {currentEffect === 'objectRemoval' &&
          <ForwardedCanvasComponent ref={objectRemoveRef}
            currentImage={currentImage} />
        }

      </div>


      <div className={styles.rightSection}>
        <h3>Effects</h3>
        <Effects handleEffectChange={handleEffectChange} currentEffect={currentEffect} />

        <div className={styles.effectUi}>
          {currentEffect === "objectRemoval" &&
            <div>
              <button onClick={() => handleCombineImages()}>
                CombineImages
              </button>

              <button onClick={clear}>
                Clear Masking
              </button>

              <button onClick={() => createBlackAndWhiteMask()}>
                GENERATE MASK</button>

              <button onClick={handleSubmitMask}>
                Submit Mask
              </button>
              Preview:
              <img src={mask} alt='mask' />
            </div>
          }

          {currentEffect === "genBackground" &&
            <GenBackground currentImage={currentImage} projectId={projectId} />
          }

          {(currentEffect !== "") &&
            <ButtonA className={styles.confirmBut} onClick={handleConfirm}>Confirm</ButtonA>
          }
        </div>
      </div>

      <button onClick={handleBlack}>black</button>
      <History
        edits={edits}
        setCurrentImage={setCurrentImage} />

      <EventListenerComponent
        setCurrentImage={setCurrentImage}
        events={events}
        setEvents={setEvents}
        setEdits={setEdits} />
    </div >
  )
}