import React, { useEffect, useState } from 'react'
import styles from './Project.module.css'
import axios from 'axios'
import { baseUrl } from '../config.js'
import { Link } from 'react-router-dom'
// import submitEdit from '../tools/submitEdit.js'
// import ForwardedCanvasComponent from '../effects/objectRemoval.js';
// import GenBackground from '../effects/genBackground.js';
// import Effects from '../ui/Effects.js';
// import EventListenerComponent from '../services/Events.js';
import { useParams } from "react-router-dom"
// import AddOns from '../ui/AddOns.js'
import History from '../ui/History.js'
// import ButtonA from '../ui/ButtonA.js'


export default function Project() {
  const { projectId } = useParams()
  const [edits, setEdits] = useState([])

  useEffect(() => {
    async function fetchEdits() {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get(`${baseUrl}/edits/${projectId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        if (response.status === 200) {
          setEdits(currentEdits => {
            const currentEditIds = new Set(currentEdits.map(edit => edit.id));

            // Filter out new edits that are already in the current edits
            const newEdits = response.data.filter(edit => !currentEditIds.has(edit.id)).reverse();

            // Combine the current edits with the filtered new edits
            return [...newEdits, ...currentEdits];
          }
          )
        }

      } catch (e) {
        console.log(e);
      }
    }

    fetchEdits();

  }, [projectId])

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.h1}>Project Edits</h1>

      <div className={styles.contents}>
        <History
          edits={edits}
        />
      </div>
      <Link className={styles.link} to='/projects'>Back To Projects</Link>
      <Link className={styles.link} to='/'>Back To Homepage</Link>

    </div>
  )
}