import React from "react"
import Login from "../tools/Login.js"
import styles from "./LoginPage.module.css"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';


export default function LoginPage() {


  return (
    <div className={styles.loginPageWrap}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://aifotoedit.com/login" />

        <title>Login Page for users to authenticate.</title>
        <meta name="description" content="Login and registration for users looking to access your photo editing projects." />
        <link rel="canonical" href="https://aifotoedit.com/login" />
      </Helmet>
      <h1 className={styles.h1}>Login</h1>

      <Login />

      {/* <Link to="/orders">Your Orders</Link>
      <Link to="/myprofile">Your Profile</Link> */}

      <Link to="/">Back to Homepage</Link>


    </div>

  )
}