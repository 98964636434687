import React, { useEffect, useRef, useState } from 'react'
import styles from './Projects.module.css'
import axios from 'axios'
import { baseUrl } from '../config.js'
import { Link, useNavigate } from 'react-router-dom'
import ButtonA from '../ui/ButtonA.js'

export default function Projects() {
  const [projects, setProjects] = useState([
  ])

  const navigate = useNavigate()

  useEffect(() => {
    async function getProjets() {
      try {
        const token = localStorage.getItem('token')


        const response = await axios.get(`${baseUrl}/projects`,
          {
            headers: { Authorization: `Bearer ${token}` },
          })

        if (response.status === 200) {
          const reverseProjects = response.data.reverse()
          setProjects(reverseProjects)
        }
      } catch (e) {
        console.log(e);
      }
    }
    getProjets();
  }, [])


  async function newProject() {
    try {
      const inputForm = {
        name: 'My New Project',
      }
      const token = localStorage.getItem('token')

      const response = await axios.post(`${baseUrl}/project`, inputForm,
        {
          headers: { Authorization: `Bearer ${token}` },
        })


      if (response.status === 201) {
        console.log(response.data);
        navigate(`/editor/${response.data._id}`, { state: { name: response.data.name } })
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={styles.wrap}>
      <h1>My Projects</h1>
      <ButtonA onClick={newProject}>+ New Project</ButtonA>
      <small>Newest First</small>

      <div className={styles.projects}>
        {projects.length > 0 && projects.map((project, index) => {
          return (
            <Link key={index}
              // to={`/editor/${project._id}`}
              to={`/project/${project._id}`}

              className={styles.project} >
              {new Date(project.createdAt).toLocaleDateString("en-US", { hour: 'numeric', minute: 'numeric' })}
            </Link>
          )
        })}
      </div>

    </div >
  )
}