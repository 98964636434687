import React, { useRef, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../config'

export default function GenBackground({ currentImage, projectId }) {

  const [inputForm, setInputForm] = useState({
    prompt: '',
    size: 'Original',
    negative: 'low quality, out of frame, illustration, 3d, sepia, painting, cartoons, sketch, watermark, text, Logo, advertisement',
    pixel: '1024 * 1024',
    scale: 3,
    imageNum: 1,
    guidance: 7.5,
    steps: 20,
    seed: 1,
  })

  async function handleSubmit() {
    try {
      let form = {
        inputImage: currentImage,
        effect: "genBackground",
        project: projectId,
        ...inputForm
      }
      const token = localStorage.getItem('token')

      const response = axios.post(`${baseUrl}/edit/`, form,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (e) {

    }
  }
  function handleChange(event) {
    setInputForm(preveForm => {
      return {
        ...preveForm,
        [event.target.name]: event.target.value
      }
    })
  }

  return (
    <div>
      <label>Prompt
        <input type="text"
          placeholder='Type your message here'
          name="prompt"
          value={inputForm.prompt} onChange={e => handleChange(e)} />
      </label>

      <label>Negative Words (exlcude)
        <input
          placeholder={inputForm.negative}
          name="negative_prompt"
          type="text"
          onChange={e => handleChange(e)}
          value={inputForm.negative} />
      </label>
      <button onClick={handleSubmit}>
        Submit</button>
    </div>
  )
}