import styles from './ButtonA.module.css'

export default function ButtonA(props) {
  const { children, onClick, style, disabled } = props;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={styles.button + " " + style}>
      {children}
    </button>
  )
}