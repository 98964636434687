export function getLoggedInUserId() {

  if (!validateToken()) {
    return false;
  }

  const token = localStorage.getItem('token')
  if (!token) return false;

  try {
    const middlePart = token.split('.')[1];
    if (!middlePart) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenTimestamp");
      throw new Error('Invalid token');
    }

    const decodedString = window.atob(middlePart);
    const decodedObj = JSON.parse(decodedString);
    return decodedObj.userId || false;

  } catch (error) {

    console.error('Error decoding token:', error);
    return false;
  }
}

export function isCreator(userIdToCompare) {

  if (!userIdToCompare) return false

  if (userIdToCompare === getLoggedInUserId()) {
    return true
  } else return false

}


export function validateToken() {
  const token = localStorage.getItem("token");
  const tokenTimestamp = localStorage.getItem("tokenTimestamp");
  // console.log("STAMPED", tokenTimestamp);
  // If there's no token or no timestamp, remove the token and return false
  if (!token || !tokenTimestamp) {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenTimestamp");
    return false;
  }

  // Check if the token is older than 7 days
  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
  if (Date.now() - parseInt(tokenTimestamp, 10) > sevenDaysInMs) {
    // Token is older than 7 days, remove it and its timestamp
    localStorage.removeItem("token");
    localStorage.removeItem("tokenTimestamp");
    return false;
  }

  // Token is valid
  return true;
}