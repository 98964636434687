import React, { useEffect } from 'react';
import { getLoggedInUserId } from '../tools/auth'
import { baseUrl } from '../config';

function EventListenerComponent({ setCurrentImage, events, setEvents, setEdits }) {

  useEffect(() => {
    const clientId = getLoggedInUserId(); // Retrieve or generate a unique client ID
    if (clientId) {
      const url = `${baseUrl}/events?clientId=${encodeURIComponent(clientId)}`;

      // Initialize the SSE connection with clientId as a query parameter
      const evtSource = new EventSource(url);

      // Listen for messages
      evtSource.onmessage = function (event) {
        const newEvent = JSON.parse(event.data);
        // Update the component's state with the new event
        console.log(newEvent);
        setCurrentImage(prev => {
          return {
            ...prev, outputImage: newEvent.outputImage
          }
        })

        setEvents(prevEvents => [newEvent, ...prevEvents]);
        setEdits(prevEvents => [newEvent, ...prevEvents])
      };

      // Close the connection when the component unmounts
      return () => evtSource.close();
    }
  }, [setCurrentImage, setEvents, setEdits]);


  return (
    <div>
      {/* <h5>Received Events</h5>
      <ul>
        {events.length > 0 && events.map((event, index) => (
          <li key={index}>
            effect: {event.effect},
            output: {event.outputImage},
            at: {event.createdAt}
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default EventListenerComponent;
