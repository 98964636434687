import React, { useImperativeHandle, useRef, useState, useEffect } from 'react'
import axios from 'axios'
import styles from './objectRemoval.module.css'
import { baseUrl } from '../config'

const ObjectRemoval = (props, ref) => {

  const { currentImage } = props;
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.width = image.width;
        canvas.height = image.height;
      }
    };
    image.src = currentImage;
  }, [currentImage]);


  const getMousePos = (canvas, evt) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;    // relationship bitmap vs. element for X
    const scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y

    return {
      x: (evt.clientX - rect.left) * scaleX,  // scale mouse coordinates after they have
      y: (evt.clientY - rect.top) * scaleY    // been adjusted to be relative to element
    }
  }


  const createBlackAndWhiteMask = () => {
    if (!canvasRef.current) {
      console.error('Canvas reference is not initialized.');
      return '';
    }

    const drawingCanvas = canvasRef.current;
    const drawingContext = drawingCanvas.getContext('2d');
    if (!drawingContext) {
      console.error('Drawing context is not available.');
      return '';
    }

    const maskCanvas = document.createElement('canvas');
    maskCanvas.width = drawingCanvas.width;
    maskCanvas.height = drawingCanvas.height;
    const maskContext = maskCanvas.getContext('2d');

    const imageData = drawingContext.getImageData(0, 0, drawingCanvas.width, drawingCanvas.height);
    const data = imageData.data;
    let maskData = new Uint8ClampedArray(data.length);

    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] > 0) {
        maskData[i] = 255;
        maskData[i + 1] = 255;
        maskData[i + 2] = 255;
        maskData[i + 3] = 255;
      } else {
        maskData[i] = 0;
        maskData[i + 1] = 0;
        maskData[i + 2] = 0;
        maskData[i + 3] = 255;
      }
    }

    let newImageData = new ImageData(maskData, drawingCanvas.width, drawingCanvas.height);
    maskContext.putImageData(newImageData, 0, 0);
    const maskDataUrl = maskCanvas.toDataURL('image/png');
    return maskDataUrl;
  };



  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const mousePos = getMousePos(canvas, e.nativeEvent);
    const context = canvas.getContext('2d');

    context.beginPath();
    context.moveTo(mousePos.x, mousePos.y);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) {
      return;
    }

    const canvas = canvasRef.current;
    const mousePos = getMousePos(canvas, e.nativeEvent);
    const context = canvas.getContext('2d');

    context.lineJoin = 'round'; // Add this line for smoother corners
    context.lineCap = 'round'; // Make the ends of lines rounded

    context.lineTo(mousePos.x, mousePos.y);

    context.strokeStyle = "rgb(255 0 0 / 1%)";
    context.fillStyle = "rgb(255 0 0 / 0%)";

    // context.strokeStyle = 'rgba(255, 0, 0, 0.1)'; // Example: red color with opacity
    context.lineWidth = 40; // Example: width of the mask line
    context.stroke();
  };


  const finishDrawing = () => {
    const context = canvasRef.current.getContext('2d');
    context.closePath();
    setIsDrawing(false);
  };

  const clear = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  async function handleSubmitMask() {
    const imageDataUrl = createBlackAndWhiteMask();
    try {
      let input = {
        inputImage: currentImage,
        effect: "objectRemoval",
        maskImage: imageDataUrl,
      }
      const token = localStorage.getItem('token')

      const response = await axios.post(`${baseUrl}/edit`, input,
        {
          headers: { Authorization: `Bearer ${token}` },
        });

    } catch (error) {
      console.error('Error saving mask:', error); // handle the error as needed
    }
  };

  const handleCombineImages = () => {
    const canvas = canvasRef.current;
    const maskCanvas = canvas.getContext('2d');

    // Set canvas size to foreground image size or any desired size
    canvas.width = maskCanvas.width;
    canvas.height = maskCanvas.height;

    // Draw a yellow rectangle as the background
    maskCanvas.fillStyle = 'yellow'; // Set fill color to yellow
    maskCanvas.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas with yellow

    // Draw the foreground image on top of the yellow background
    // Adjust position as needed; this example centers the image on the canvas
    const fgX = (canvas.width - maskCanvas.width) / 2;
    const fgY = (canvas.height - maskCanvas.height) / 2;

    maskCanvas.drawImage(maskCanvas, fgX, fgY);
    const maskDataUrl = maskCanvas.toDataURL('image/png');

    console.log(maskDataUrl);
    return maskDataUrl;
  };


  function blackBackground() {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.rect(0, 0, canvas.width, canvas.height)

    ctx.fillStyle = 'black'
    ctx.fill();
  }



  useImperativeHandle(ref, () => ({
    clear,
    handleSubmitMask,
    createBlackAndWhiteMask,
    handleCombineImages,
    blackBackground
  }));


  return (
    <canvas
      ref={canvasRef}
      onMouseDown={startDrawing}
      onMouseUp={finishDrawing}
      onMouseOut={finishDrawing}
      onMouseMove={draw}
      className={styles.currentImageCanvas}
      alt="edit canvas"
    />
  )
}

export default React.forwardRef(ObjectRemoval);
