import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"

import styles from './Home.module.css';
import Effects from "../ui/Effects";
// import DragnDrop from "../ui/DragnDrop";
import submitEdit from "../tools/submitEdit";
import ButtonA from "../ui/ButtonA";
import ButtonC from "../ui/ButtonC";
import axios from 'axios'
import { baseUrl } from "../config";
import EventListenerComponent from '../services/Events.js';

// import tokensCheckout from "../submission/tokens.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getLoggedInUserId } from "../tools/auth.js";

export default function Home() {
  const logged = getLoggedInUserId();
  const [button, setButton] = useState('Submit');

  const [subscribed, setSubscribed] = useState(false)

  const [urlMode, setUrlMode] = useState(true)
  // const [files, setFiles] = useState([]);
  const [maskImage, setMaskImage] = useState('')

  const [currentEffect, setCurrentEffect] = useState({});
  const [error, setError] = useState('')
  const [status, setStatus] = useState('')

  const [events, setEvents] = useState([])
  const [edits, setEdits] = useState([])


  const [currentImage, setCurrentImage] = useState({
    inputImage: '',
    outputImage: ''
  });


  async function getSubscription() {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${baseUrl}/getsubscritpion`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      console.log(response.data);
      if (response.status === 200) {
        setSubscribed(true)
      } else {
        setSubscribed(false)
        setError('You do not have an active subscription.')
      }

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    token ? getSubscription() : setError('You are not logged in.')

  }, [])


  // async function uploadFilesToS3() {
  //   try {
  //     const response = axios.post()
  //     return true
  //   } catch (e) {
  //     console.log(e);
  //     return false
  //   }
  // }

  function resetButton() {
    setButton('Submit')
  }

  function validate() {

    if (!logged) {
      setError('You need to login first.')
      resetButton();
      return false
    }

    if (!currentImage.inputImage) {
      setError('Select Image First')
      resetButton();
      return false
    }
    if (!currentEffect) {
      setError('Select Effect First')
      resetButton();
      return false
    }


    return true
  }



  async function handleConfirm() {
    setButton('Submitting..')

    const validInput = validate();
    if (!validInput) return


    try {
      let form = {
        inputImage: currentImage.inputImage,
        effect: currentEffect.dataEffect,
        maskImage: maskImage ? maskImage : '',
        project: '',
      }
      const response = await submitEdit(form);


    } catch (e) {
      console.log('error', e);
    }
    resetButton();
  }



  // async function authenticate() {

  // }

  // async function handleSubmit() {

  // setStatus('Authenticating')
  // const token = localStorage.getItem('token')
  // if (!token) setError('You need to login or register first.')

  // setStatus('Confirming with server')
  // const checkTokensAndCharge = getProfile();
  // if (!checkTokensAndCharge) setError('You need more tokens.')

  // setStatus('Uplaoding images')
  // const uploadPhotos = uploadFilesToS3();
  // if (!uploadPhotos) setError('File upload failed, please contact support.')

  //   setStatus('Commencing processing')
  //   const submit = handleConfirm();
  //   if (!submit) setError('Submission failed, please contact support')

  //   setStatus('Processing images...')
  // }


  function handleEffectChange(object) {
    setCurrentEffect(object);
  }


  function handleChange(e) {
    setCurrentImage(prev => {
      return {
        ...prev,
        inputImage: e.target.value
      }
    })
  };


  return (
    <div className={styles.wrapMain}>

      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />

          <title>AI Foto Edit - Remove Background.</title>
          <meta name="description"
            content="Transform your photos effortlessly with AI Foto Edit - a friendly AI platform for enhancing & editing your photos with ease. Great for both personal and brand photography, get white background (white backround), blank background, upscale images, remove blur and out of focus, AI images fix and image restoration - all in 2 taps." />

          <link rel="canonical" href="https://aifotoedit.com/" />
          <meta property="og:title" content="AI Foto Edit - Remove Background" />

          <meta property="og:url" content="https://aifotoedit.com" />
          <meta property="og:image" content="https://aifotoedit.com/social-og2-small-sq.jpg" />
          <meta property="og:description"
            content="Transform your photos effortlessly with AI Foto Edit - a friendly AI platform for enhancing & editing your photos with ease. Great for both personal and brand photography, get white background (white backround), blank background, upscale images, remove blur and out of focus, AI images fix and image restoration - all in 2 taps." />


          <meta name="twitter:description" content="Transform your photos effortlessly with AI Foto Edit – your one-stop solution for background removal, image upscaling, blur reduction, and restoration. Perfect your fotos in just two taps, choosing from a wide range of AI effects including background generation and object removal. Elevate your imagery today!" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@aifotoedit" />
          <meta name="twitter:title" content="AI Foto Edit - Remove Background" />
          <meta name="twitter:creator" content="@dvebene" />
          <meta property="twitter:image" content="https://aifotoedit.com/social-og2-small-sq.jpg" />
          <meta name="twitter:url" content="https://aifotoedit.com" />
        </Helmet>
      </HelmetProvider>

      <h1>AI Foto Edit</h1>

      <h2>Remove Background from Fotos & Images with AI.<br />
        Upscale, Fix or Restore Fotos in 2 taps.</h2>

      <div className={styles.upload}>

        <h3>Select foto to edit or add effect</h3>
        <div className={styles.parts}>

          <div className={styles.partOne}>
            <h4 className={styles.label}>
              <label className={styles.number}>1</label>
              <p className={styles.labelp}>
                Select Foto</p>
            </h4>

            {/* <span>
              <ButtonC onClick={() => setUrlMode(true)}>Enter Image Link</ButtonC> or
              <ButtonA onClick={() => setUrlMode(false)}>Upload Image</ButtonA>
            </span> */}

            {urlMode
              &&
              <label className={styles.labelInput}>
                Enter Image Link:
                <input type="url"
                  value={currentImage?.inputImage}
                  onChange={handleChange}
                  className={styles.inputImageUrl} />
              </label>
            }


            {currentImage.inputImage
              && <div className={styles.centerSection}>
                <img src={currentImage.outputImage || currentImage.inputImage}
                  className={styles.currentImage}
                  alt='preview' />
              </div>}

            {/* {!urlMode && <DragnDrop files={files} setFiles={setFiles} />
            } */}

            {/* <p>Accptable Formats:  .</p> */}

          </div>




          <div className={styles.partOne}>

            <h4 className={styles.label}>
              <label className={styles.number}>2</label>
              <p className={styles.labelp}>
                Select AI Effect
              </p>
            </h4>

            {currentEffect.description ?
              <p>{currentEffect.description}</p>
              :
              <p>Select Effect for details</p>
            }

            <div className={styles.effects}>

              <Effects
                handleEffectChange={handleEffectChange}
                currentEffect={currentEffect} />
            </div>
          </div>



          <div className={styles.partOne}>
            <h4 className={styles.label}>
              <label className={styles.number}>3</label>
              <p className={styles.labelp}>
                Confrim Transformation</p>
            </h4>


            {error && <p className={styles.error}>{error}</p>}

            <ButtonA onClick={handleConfirm}
              style={styles.confirmBut}
            // disabled={true}
            >{button}
            </ButtonA>

            {status && <p className={styles.status}>{status}</p>}


            {/* <small>Submission requires 1 token.</small> */}


          </div>

          <div className={styles.partOne}>
            <h4 className={styles.label}>
              <label className={styles.number}>4</label>
              <p className={styles.labelp}>
                The Result
              </p>
            </h4>

            {currentImage?.outputImage
              ? <img src={currentImage.outputImage}
                alt='user edit output' />
              :
              <p>Please submit edit first.</p>}
          </div>
        </div>
      </div>

      <Link to='/projects'>Your Projects {'>'}</Link>

      <EventListenerComponent
        setCurrentImage={setCurrentImage}
        events={events}
        setEvents={setEvents}
        setEdits={setEdits} />

    </div >

  );
}


