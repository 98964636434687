import Effect from "./Effect"
import styles from './Effects.module.css'

export default function Effects({ handleEffectChange, currentEffect }) {

  const effectsData = [
    {
      dataEffect: 'upscale4K',
      label: 'Upscale 4K',
      description: 'Increases details and pixels of an image to 4000 x 4000 pixels.'
    },
    {
      dataEffect: 'backgroundRemove',
      label: 'Remove Background',
      description: 'Removes background behind main subject of an image, e.g. a portrait.'
    },
    {
      dataEffect: 'removeBlur',
      label: 'Remove Blur',
      description: 'Increases focus in blurry regions of an image.'

    },
    {
      dataEffect: 'fixAi',
      label: 'Fix AI Image',
      description: 'Fixes deformaties in picutres'
    },
    {
      dataEffect: 'restore',
      label: 'Restore Photo',
      description: 'Fixes old photos by improving colors and and irons out deformaties.'
    },
    // {
    //   dataEffect: 'genBackground',
    //   label: '*Generate Background*'
    // },
    // {
    //   dataEffect: 'objectRemoval*',
    //   label: '*Remove Object*'
    // },
    // {
    //   dataEffect: 'addColour',
    //   label: 'Add Color to B&W Image'
    // },


  ]

  return (
    <div className={styles.effects}>
      {effectsData.map((effectObject, index) => {
        return (
          <Effect
            key={index}
            handleEffectChange={handleEffectChange}
            effectObject={effectObject}
            currentEffect={currentEffect}>
            {effectObject.label}
          </Effect>
        )
      })}
    </div>
  )
}