import React from 'react';
import styles from './App.module.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
import Editor from './components/Editor';
import Navbar from './ui/Navbar';
import Projects from './components/Projects';
import Footer from './components/Footer.js'
import LoginPage from './components/LoginPage.js';
import Home from './components/Home.js';
import Project from './components/Project.js';
import Pricing from './components/Pricing.js';

function App() {
  return (
    <div className={styles.App}>
      <Router>
        <header>
          <Navbar />
          {/* <News /> */}
          {/* <NewsTop /> */}
        </header>

        <div className="Content">
          <Suspense fallback={<div style={{ height: '100vh' }}>Loading Page Component...</div>}>

            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/project/:projectId" element={<Project />} />
              <Route path="/editor/:projectId" element={<Editor />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/login" element={<LoginPage />} />

              <Route path="/value-price" element={<Pricing />} />

            </Routes>
          </Suspense>
        </div>
        <Footer />

      </Router>
    </div>
  );
}

export default App;
