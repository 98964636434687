import React from 'react'
import styles from './Pricing.module.css'
import ButtonA from '../ui/ButtonA'

export default function Pricing() {

  function handleClick(pack) {

  }
  return (
    <div className={styles.wrapper}>
      <h1>Value & Price</h1>

      <h2>Subscriptions</h2>

      <div className={styles.cards}>

        <div className={styles.card}>
          <h3>Monthly</h3>
          <p className={styles.price}>
            $9
          </p>
          <p>Cancel anytime</p>

          <ButtonA oncClick={() => handleClick(1)} style={styles.getit}>Get It</ButtonA>

          <ul className={styles.ul}>
            <li>Monthly fair usage: 5,000 edits</li>
            <li>Price transperancy: $0,0018 / edit</li>

          </ul>

        </div>

        <div className={styles.card}>
          <h3>Yearly</h3>
          <p className={styles.price}>
            $89
          </p>
          <p>18% cheaper</p>


          <ButtonA oncClick={() => handleClick(2)} style={styles.getit}>Get It</ButtonA>
          <ul className={styles.ul}>
            <li>Monthly fair usage: 5,000 edits</li>
            <li>Price transperancy: $0,0015 / edit</li>

          </ul>

        </div>

        <div className={styles.card}>
          <h3>Yearly Pro</h3>
          <p className={styles.price}>
            $179
          </p>

          <p>33% cheaper</p>
          <ButtonA oncClick={() => handleClick(3)} style={styles.getit}>Get It</ButtonA>

          <ul className={styles.ul}>
            <li>Monthly fair usage: 15,000 edits</li>
            <li>Price transperancy: $0,0010 / edit</li>

          </ul>

        </div>




      </div>





    </div>
  )
}