import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getLoggedInUserId } from "../tools/auth.js"
import styles from "./Navbar.module.css";
// import logo from "../../Assets/ailogo.webp";
// import Login from "../Tools/Login.js";
// import profileIcon from "../../Assets/icons/account.png"
// import petIcon from "../../Assets/icons/pawprint.png"
// import galleryIcon from "../../Assets/icons/gallery.webp"
// import pawIcon from "../../Assets/icons/pawprint.png"
// import logoutIcon from "../../Assets/icons/logout.png"
// import homeIcon from "../../Assets/icons/home.png"
// import closeIcon from "../../Assets/icons/close-2.png"
// import LanguageChange from "../Tools/LanguageChange.js";

export default function Navbar() {
  const languages = ['English', 'Spanish']
  // const [language, setLanguage] = useState(languages[0])


  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(getLoggedInUserId()))
  const [token, setToken] = useState(localStorage.getItem('token')); // New state for token
  // const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token') // remove token from local storage on logout
    localStorage.removeItem('affrefid') // remove affid from local storage on logout

    setIsLoggedIn(false); // update state
    setToken(null); // Update token state
    // navigate('/')
  }

  // new Image().src = logo

  useEffect(() => {

    const userId = getLoggedInUserId();

    setIsLoggedIn(Boolean(userId));
    setIsOpen(false);
    setToken(localStorage.getItem('token')); // Update token state when location changes
  }, [location, token]); // Add token state to dependency array

  // Function to toggle isOpen state
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className={styles.navbarouterwrap}>
      <div className={styles.navbarWrap}>

        {/* left */}
        <div className={styles.logoAreaWrap}>
          <NavLink to="/" className={styles.navLogo}>
            {/* <img src={logo} alt="ai pet photos logo" className={styles.logo} /> */}
            <p className={styles.slogan}>
              AI Foto Edit
            </p>
          </NavLink>
        </div>


        <div className={styles.lang}>
          {/* <LanguageChange /> */}
          <button onClick={handleToggle} className={styles.burger}>
            MENU
          </button>
        </div>


        {/* right */}
        <div className={isOpen ? styles.navLinksOpen : styles.navLinks}>
          <NavLink to="/" className={location.pathname === '/' ? styles.activeLink : styles.notActive}>
            {/* <img className={styles.icon} src={homeIcon} alt="home icon" /> */}
            Home</NavLink>
          {/* <NavLink to="/projects" className={styles.notActive}> */}
          {/* <img className={styles.icon} src={pawIcon} alt="profile icon" /> */}
          {/* Projects</NavLink> */}
          {/* <NavLink to="/product/dog-portrait-ai-4k" className={styles.notActive}> */}
          {/* <img className={styles.icon} src={pawIcon} alt="profile icon" /> */}
          {/* Dogs</NavLink> */}
          {/* <NavLink to="/pet-portraits-gallery/1" className={location.pathname === '/pet-portraits-gallery' ? styles.activeLink : styles.notActive}> */}
          {/* <img className={styles.icon} src={galleryIcon} alt="profile icon" /> */}
          {/* Gallery</NavLink> */}


          <NavLink to="/value-price" className={location.pathname === '/value-price' ? styles.activeLink : styles.notActive}>
            {/* <img className={styles.icon} src={petIcon} alt="paw print icon" /> */}
            Value & Prices
          </NavLink>

          {isLoggedIn && <NavLink to="/projects" className={location.pathname === '/orders' ? styles.activeLink : styles.notActive}>
            {/* <img className={styles.icon} src={petIcon} alt="paw print icon" /> */}
            My Projects
          </NavLink>}

          {/* {isLoggedIn && <NavLink to="/myprofile" className={location.pathname === '/myprofile' ? styles.activeLink : styles.notActive}> */}
            {/* <img className={styles.icon} src={profileIcon} alt="profile icon" /> */}
            {/* Account</NavLink>} */}

          {isLoggedIn &&
            <button onClick={handleLogout} className={styles.logoutButton}>
              {/* <img className={styles.icon} src={logoutIcon} alt="logout icon" /> */}
              Logout</button>}


          {!isLoggedIn && <NavLink to="/login" className={location.pathname === '/login' ? styles.activeLink : styles.notActive}>
            {/* <img className={styles.icon} src={profileIcon} alt="profile icon" /> */}
            Login | Register</NavLink>}
          {/* <LanguageChange /> */}


          {isOpen && <button
            onClick={handleToggle}
            className={styles.closeButton}>
            {/* <img className={styles.icon} src={closeIcon} alt="close menu icon" /> */}
            Close Menu</button>}

        </div>
      </div>
    </div>
  );
}
